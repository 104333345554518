body{
    background-color: #181924;
}
.nav-element-green {
    font-size: 12px;
    padding-right: 5px;
    min-width: 30px;
    color: #14DE99;}

.nav-element-red {
    font-size: 12px;
    padding-right: 5px;
    min-width: 30px;
    color: #FF4141;
}
.nav-element-yellow {
      font-size: 12px;
      padding-right: 5px;
      min-width: 30px;
      color: #FFB820;
}
.btn .btn-primary .btn-lg {
    color: #fff;
    background-color: #7267D3 !important;
}
.c-sidebar-nav-dropdown-items .c-sidebar-nav-link {
    padding-left: 20px !important;
}
.card-body {
    box-shadow: 1px 1px 5px #000;
}